.main {
  width: fit-content;
  margin: 193px auto;
  border-radius: 20px;
  background: #FFF;
  &:focus-visible{
  outline: none;
  }
}

