button{
    border: none;
    background: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-align: center;

    &:disabled,{
        color: rgba(0, 0, 0, 0.30);
        cursor: not-allowed;
    }
}

.primary{
    color: #000000;
}
.secondary{
    color: #FFB23F;
}
.negative{
    color: #FF4D35;
}