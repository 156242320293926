.emailPartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  .content{
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: center;
    .mainBtn{
      margin: 30px 0 33px;
    }
    .questionText {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .txtBtn{
      margin-top: 6px;
    }
  }
}
.aboutPartContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  .content{
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 38px;

    .txtBtn{
    margin: -8px 0 151px;
    }
  }

}
.accountPlanContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  .content{
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 105px;
    margin: 38px 0 88px;
    .planCard{
      display: flex;
      flex-direction: column;
      align-items: start;
      .planCardTitle{
        color: #000;
        font-size: 32px;
        font-weight: 500;
      };
      .planCardSubTitle{
        max-width: 385px;
        color: #878787;
        font-size: 18px;
        font-weight: 400;
      };
      .priceBlock{
        display: flex;
        gap: 20px;
        align-items: center;
        div{
          display: flex;
          align-items: flex-start;
          gap: 4px;
          color: #000;
          font-size: 82px;
          font-weight: 700;

          svg{
            margin-top: 33px;
          }
        }
      };
      .planBtn{
        width: 424px;
      }
      .abilitiesBlock{
        margin-top: 26px;
        display: flex;
        gap: 18px;
        flex-direction: column;

        .line{
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 9.5px;

        }
        p{
          text-align: start;
          color: #000;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

  }
  .txtBtn{
    margin: -8px 0 151px;
  }
  .allPriceBtn{
    cursor: pointer;
    color: #878787;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin: 18px 0 22px;

     }
}
.simpleBtn{
  display: inline-flex;
  height: 50px;
  padding: 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;

}
.comparePlaneContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .tableColumn{
    display: flex;
    width: 1198px;
    justify-content: space-between;
    font-size: 16px;
    padding: 20px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #EBEBEB;
    }

    .tableColumnLeft{
      color: #878787;
      font-weight: 400;
    }
    .tableColumnRight{
      display: flex;
      width: 40%;
      justify-content: space-between;
      text-align: center;
      div{
        width: 120px;
        text-align: center;
        color: #000;
        font-size: 16px;
        font-weight: 500;

      }
    }
    .tableHeadColumnLeft{
      color: #000;
      font-size: 18px;
      font-weight: 500;
    }
    .tableHeadColumnRight{
      display: flex;
      width: 40%;
      justify-content: space-between;
      text-align: center;
      div{
        width: 120px;
        text-align: center;
        color: #878787;
        font-size: 18px;
        font-weight: 500;
      }

    }
  }
}


