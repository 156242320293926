.container{
    width: 100%;
    position: relative;

    .selectInputContainer{
        width: inherit;
        position: relative;

        .selectIcon{
            position: absolute;
            cursor: pointer;
            right: 20px;
            transition: transform 0.5s ease;
            top: 24px;
            transform: translateY(-50%);        }
        .rotated{
            transform: rotate(180deg) translateY(50%);
        }
        .disabled-input {
            background-color: transparent;
        } input[disabled] {
            @extend .disabled-input;
        }
    }
    .optionsContainer{
        position: absolute;
        z-index: 9;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        width: 100%;
        top: 60px;
        left: 0;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
        font-size: 18px;
        color: #000;
        padding: 0 20px;

        .option{
            width: 100%;
            text-align: center;
            padding: 13px 0;
            cursor: pointer;
            &:not(:last-child){
                border-bottom: 1px solid #EBEBEB;
            }
            &:hover{
                background: rgba(0, 0, 0, 0.04);
            }
        }
    }
}