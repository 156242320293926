.audio {
    padding: 22px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    border: 1px solid #878787;
    width: 100%;
    .audioInfo {
        display: flex;
        max-width: 390px;
        flex-direction: column;
        gap: 5px;
        .title {
            max-width: 560px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            margin: 0;
            font-weight: 400;
        }
        .time {
            font-size: 16px;
            color: #878787;
            margin: 0;
        }
    }
    .delete {
        font-size: 16px;
        font-weight: 500;
        background-color: #fff;
        border: none;
        cursor: pointer;
    }
}