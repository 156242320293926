.loading {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 10px;
}

.loading_dot {
    animation: dot ease-in-out 1s infinite;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius:50%;
    display: inline-block;
    height: 10px;
    width: 10px;
    &:nth-of-type(2) {
        animation-delay: 0.2s;
    }
    &:nth-of-type(3){
        animation-delay: 0.3s;
    }
}

@keyframes dot {
    0% { background-color: rgba(0, 0, 0, 0.15); transform: scale(1); }
    50% { background-color: rgba(0, 0, 0, 0.30); transform: scale(1.1);  }
    100% { background-color: rgba(0, 0, 0, 0.15); transform: scale(1);}
}