.supportContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  .content{
    display: flex;
    width: 639px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  button{
    max-width: 424px;
    margin-bottom: 143px;
  }
}