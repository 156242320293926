.container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    textarea{
        width:100%;
        padding: 20px;
        align-items: flex-start;
        flex-shrink: 0;
        resize: none;
        border-radius: 5px;
        border: 1px solid #878787;
        outline-width: 0;
        color: #000;
        font-size: 18px;
        font-weight: 400;
        &::placeholder {
            color: #878787;
            }
    }
    .error {
        border: 1px solid #FF4D35;
    }
    .mainLabel{
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .smallLabel{
        color: #878787;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.errorMessage{
    color: red;
    font-size: 14px;
    margin-top: 4px;
}
