.payoutsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  .content{
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .addPayoutBtn{
    width: 424px;
  }
  .viewPayoutBtn{
    width: 424px;
  }
  .payoutsContent{
    display: flex;
    width: 950px;
    flex-direction: column;
    align-items: center;
    gap: 38px;
    .totalFounds{
      width: 100%;
      border-radius: 10px;
      background: #000;
      padding: 28px;
      color: #FFF;
      text-align: center;
      span{
        font-size: 32px;
        font-weight: 500;
      }
      p{
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        gap: 5px;
      }
      .hiddenText {
        width: 365px;
        opacity: 0;
        position: absolute;
        top: -137px;
        left: 17px;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
        padding: 20px;
        justify-content: center;
        align-items: center;
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .infoIconBlock{
        position: relative;

        &:hover {
          .hiddenText {
            opacity: 1;
          }
        }
      }
    }
  }
   .bankAccountCard{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #878787;
    padding: 22px 40px;
    .updateDate{
      color: #878787;
      font-size: 16px;
      font-weight: 400;
    }
    .endDate{
      color: #000;
      font-size: 18px;
      font-weight: 400;
    }
  }

}