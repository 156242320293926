.premiumContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  .mainBtn{
    width: 424px;
  }
  .uploadContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 42px;
    margin-bottom: 246px;
    .form{
      width: 424px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
    .summaryInfo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .summaryItem{
        width: 787px;
        color: #878787;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        padding: 20px;
        border-bottom: 1px solid #EBEBEB;
      }
    }
    .police{
      display: flex;
      width: 787px;
      justify-content: space-between;
      gap: 19px;

      div{
        cursor: pointer;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.10);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.10) inset;
    }
      p{
        color: #878787;
        font-size: 16px;
        font-weight: 400;
      }
      .checked{
        background: #000;      }
    }
  }
  .btnBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
    margin-bottom: 150px;
  }
  .dataContent{
    display: flex;
    width: 940px;
    flex-direction: column;
    gap: 28px;
    margin-bottom: 204px;
    .contentHeader{
      display: flex;
      justify-content: space-between;

      .contentFilter{
        display: inline-flex;
        padding: 10px;
        align-items: flex-start;
        gap: 40px;
        border-radius: 50px;
        background: #F4F4F4;


        div{
          display: flex;
          padding: 10px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #878787;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
        }
        .active{
          border-radius: 50px;
          background: #000;
          color: #FFF;
          cursor: default;

        }
      }
      button{
        width: 181px;
      }
    }
    .cardContent{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 20px;
      flex: 3;
      div{
        height: 300px;
        width: 300px;
        border-radius: 5px;
        background: #D9D9D9;
        position: relative;
        cursor: pointer;

        span{
          position: absolute;
          bottom: 0;
          display: flex;
          width: 300px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 0px 0px 5px 5px;
          background: rgba(0, 0, 0, 0.50);
          backdrop-filter: blur(6px);
          color: #FFF;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .uploadedFilesBlock{
    display: flex;
    gap: 20px;
  }
  .lockedTeaser{
    position: relative;
    width: 550px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #E3E3E3;

    button{
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.20);
      backdrop-filter: blur(6px);
      display: inline-flex;
      padding: 20px;
      justify-content: center;
      align-items: center;
      color: #FFF;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);

    }

}
}
