.simpleHeaderWrapper{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    height: 85px;
    background: #FFFFFF;
    padding: 20px 0 0 20px;
    margin-bottom: 108px;
    img{
        cursor: pointer;
        width: 189px;
    }

}