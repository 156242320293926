.mainSection {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 42px;
    .mainWrapper {
        position: relative;
        .mainImageWrapper {
            width: 550px;
            height: 550px;
            border-radius: 20px;
            border: 1px dashed #878787;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            cursor: pointer;
        }
        .mainAudioWrapper {
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            max-width: 748px;
            width: 100%;
        }
            .emptyAudioBlock{
                border-radius: 5px;
                border: 1px dashed #878787;
                padding: 18px;
            }
        .text {
            margin-top: 22px;
            font-size: 16px;
        }
        .title {
            text-align: center;
            font-size: 18px;
            margin: 0;
            span {
                font-weight: 500;
                text-decoration-line: underline;
            }
        }
         .typesTitle {
             max-width: 420px;
            display: flex;
             justify-content: center;
             text-align: center;
            color: #878787;
            font-size: 18px;
            margin: 0;
        }
        .wrapperTitle{
            margin-bottom: 20px;
            color: #000;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .disabled{
            pointer-events: none;
        }
    }
}

