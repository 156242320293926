.simpleHeaderWrapper{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    height: 85px;
    background: #FFFFFF;
    padding: 20px 0 0 20px;
    margin-bottom: 108px;
    img{
        cursor: pointer;
        width: 189px;
    }
}
.headerWrapper{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    height: 85px;
    margin-bottom: 108px;
    background: #000000;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    img{
        cursor: pointer;
        width: 189px;
    }
    .navigation{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 78px;
        .currentLink{
            color: #878787;
            cursor: pointer;
            transition: 0.4s;
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            &.active {
                color: #FFFFFF;
            }
            &:hover{
                color: #FFFFFF;
            }
        }
        .profile{
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-right: 23px;

            p{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .userEmail{
                max-width: 300px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color:#FFB23F;
                cursor: pointer;
                font-size: 18px;
                font-weight: 500;
                transition: 0.4s;
            }
            span{
                position: absolute;
                cursor: pointer;
                right: -23px;
                transition: transform 0.5s ease;
                top: 50%;
                transform:  translateY(-50%);
            }
            .profileSettings{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 13px;
                color: #000;
                font-size: 16px;
                font-weight: 400;
                .userInfo{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 38px;

                    .settingsBtn{
                        cursor: pointer;
                    }

                }
            }
        }
        .rotated{
            span{
                transform: rotate(180deg) translateY(50%);
            }
        }
        .profileSettings{
            position: absolute;
            top: 30px;
            right: -23px;
            width: 250px;
            padding: 13px 20px;
            border-radius: 5px;
            background: #FFF;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

            .signOut{
                cursor: pointer;
                padding-top: 13px;
                border-top: 1px solid #EBEBEB;
                color: #FF4D35;
            }
        }
    }
}