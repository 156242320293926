.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 22px;
    .options{
        margin-top: 16px;
        display: flex;
        gap: 20px;
        .card{
            width: 300px;
            height: 133px;
            border: 1px solid #000;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            div{
                &:first-child{
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                &:last-child{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
        .mainCard{
            background: #000;
            div{
                color: #FFF;
            }
        }
    }
    .refLink{
        margin-top: 46px;
        display: flex;
        .refValue{
            width: 638px;
            height: 50px;
            border-radius: 5px 0px 0px 5px;
            border: 1px solid #878787;
            padding: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #878787;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .copyButton{
            position: relative;
            border-radius: 0px 5px 5px 0px;
            background: #000;
            padding: 20px;
            width: 115px;
            height: 50px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            .hiddenText {
                width: 231px;
                position: absolute;
                top: -80px;
                left: -60px;
                transition:opacity 0.3s ease-in-out;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
                padding: 20px;
                justify-content: center;
                align-items: center;
                color: #000;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}