.personContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  .firstPersonaContent{
    display: flex;
    width: 865px;
    flex-direction: column;
    align-items: center;

    .contentText{
      display: flex;
      margin: 38px 0 68px;
      flex-direction: column;
      gap: 20px;

      .textBlock{
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 5px;
        &:not(:last-child){
          padding-bottom: 20px;
          border-bottom: 1px solid #EBEBEB;;

        }
        p{
          color: #000;
          font-size: 18px;
          font-weight: 500;
        }
        div{
          color: #878787;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  .personaFormContent{
    display: flex;
    width: 950px;
    flex-direction: column;
    align-items: center;

    .form{
      display: flex;
      margin: 38px 0;
      flex-direction: column;
      gap: 22px;

      .telegramBlock{
        display: flex;
        gap: 20px;

        .exampleText{
          min-width: 495px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p{
            color: #878787;
            font-size: 14px;
            font-weight: 400;
          }
          div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            span{
              color: #3D89E5;
              max-width: 220px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            svg{
              padding-left: 5px;
            }
          }
        }
      }
     .formBtn{
       margin: 12px auto 0;
       width: 424px;
     }
    }
    .formLine{
      display: flex;
      flex-direction: row;
      gap:20px;

    }
    }
    .mainBtn{
      width: 424px;
    }
  .summaryContent{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .contentItem{
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-bottom: 20px;
      border-bottom: 1px solid #EBEBEB ;
      font-weight: 400;

      p{
        color: #878787;
        font-size: 16px;
      }
      div{
        color: #000;
        font-size: 18px;
      }
    }
  }
  .hintText{
    margin: 38px 0;
    color: #828282;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  .backBtn{
      margin: 22px 0 151px;
     }
  .prepareBtn{
    width: 424px;
    margin-top: 38px;
  }
  .performanceContent{
    width: 950px;
    margin-top: 38px;
    display: flex;
    flex-direction: column;
    gap: 38px;
    justify-content: center;
  }
  .personaBlock{
    display: flex;
    width: 100%;
    height: 100px;
    border-radius: 10px;
    border: 1px solid #878787;
    padding: 22px 40px;
    justify-content: space-between;

    .textBlock{
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content:space-between;
      font-weight: 400;

      p{
        color: #000;
        font-size: 18px;
      }
      span{
        color: #878787;
        font-size: 16px;
      }
    }
    .btnBlock{
      display: flex;
      gap: 20px;
      position: relative;

      .hiddenText {
        width: 260px;
        opacity: 0;
        position: absolute;
        top: -120px;
        left: -82px;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
        padding: 20px;
        justify-content: center;
        align-items: center;
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .pendingBtn{
        color: #FFFFFF;
        cursor: default;
      }
      &:hover {
        .hiddenText {
          opacity: 1;
        }
      }
    }
  }
  .periodBlock{
    width: fit-content;
    margin: auto;
    display: inline-flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    gap: 40px;
    border-radius: 50px;
    background: #F4F4F4;
    div{
      display: flex;
      cursor: pointer;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #878787;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .activePeriod{
    cursor: default;
    border-radius: 50px;
    background: #000;
    color: #FFFFFF !important;
    font-weight: 500;
  }
  .totalRevenue{
    width: 950px;
    height: 133px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #000;
    padding: 28px;
    p{
      color: #FFF;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
    }
    div{
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      margin: auto;
    }
  }
  .revenuesBlock{
    display: inline-flex;
    align-items: flex-start;
    gap: 22px;
    margin-bottom: 78px;

    div{
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      height: 133px;
      flex: 1;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid #878787;
      p{
        color: #000;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
      }
      div{
        color: #000;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .performanceText{
    margin: 22px 0 200px;
    width: 820px;
    color: #878787;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
}