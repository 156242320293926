.signInContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  .content{
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: center;
    .passInput{
      margin-top: 30px;
    }
    .forgotPassBtn{
      width: 100%;
      cursor: pointer;
      color: #878787;
      text-align: end;
      font-size: 16px;
      font-weight: 400;
      margin: 10px 0;
    }
    .questionText {
      margin: 33px 0 6px;
      color: #000;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}