.cropper {
    width: 100% !important; 
    height: 100% !important;
    overflow: hidden;
    video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    .removeIcon{
        z-index: 9;
        width: 60px;
        height: 60px;
        position: absolute;
        top: 16px;
        right: 16px;

    }
}