.profileContent{
  width: 424px;
  margin: 38px auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.changePassContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  .content {
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: center;
    gap: 30px;

  }
  .successBackBtn{
    width: 424px;
  }

}