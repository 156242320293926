.main {
  position: relative;
  width: 100%;
  height: 100%;
  .cropperWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .cropper {
      width: 100% !important; 
      height: 100% !important;
      overflow: hidden;
    }
    .cropper div {
      width: 100%; 
      height: 100%;
        img {
            width: 100%; 
            height: 100%;
            object-fit: cover;
        }
    }
  }
  .zoomControls {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(6px);
    border-radius: 5px;
    .zoomBtn {
        width: 60px;
        height: 60px;
        background: rgba(255, 255, 255, 0.50);
        border: none;
        backdrop-filter: blur(6px);
        cursor: pointer;
        &:first-child {
            border-radius: 5px;
            border-bottom: 1px solid #EBEBEB;
        }
        &:last-child {
            border-radius: 5px;
        }
    }
  }
  .removeBtn{
    z-index: 999;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.50);
    border: none;
    backdrop-filter: blur(6px);
    cursor: pointer;
  }
}
.removeIcon{
  z-index: 9;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 16px;
  right: 16px;

}
