.inputWrapper {
    position: relative;
    width: 100%;
    input.middle:focus {
        outline-width: 0;
    }
    .inputContainer{
        width: inherit;
        position: relative;
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
    }
    .input {
        height: 50px;
        padding: 20px;
        border: 1px solid #878787;
        border-radius: 5px;
        font-size: 18px;
        color: #000;
        text-align: start;
        font-weight: 400;
        width: 100%;
        outline-width: 0;
        &::placeholder {
            color: #878787;
        }
    }
    .readOnly{
        color: #878787;
    }

    .inputError {
        border: 1px solid #FF4D35;
    }
    .label {
        margin-bottom: 4px;
        font-weight: bold;
    }


    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
    }

    .error {
        color: red;
        font-size: 14px;
        margin-top: 4px;
    }
    .postFix{
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        color: #878787;
        font-size: 18px;
        font-weight: 400;

    }
    .inputEye{
        position: absolute;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
    }
}
.passErrors{
    margin-top: 10px;
    width: 100%;
    align-items: start;
    color: #878787;
    font-size: 16px;
    font-weight: 400;
    .errorList{
        list-style-position:inside;
        color: #878787;
        font-size: 16px;
        font-weight: 400;
    }
}
 .errorMessage{
    color: red;
    font-size: 14px;
    margin-top: 4px;
}
