.container{
    width: 100%;
    border: none;
    height: 50px;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

    &:disabled,{
        background: rgba(0, 0, 0, 0.10);
        color: rgba(0, 0, 0, 0.30);
        cursor: not-allowed;
    }
}

.primary{
    background: #000;
    color: #FFFFFF;
}
.secondary{
    background: #FFB23F;
    color: #000000;
}
.active{
    background: #1BBF00;
    color: #FFFFFF;
    cursor: default;
}
.secondaryLoadingStyles{
    background: rgba(255, 178, 63, 0.35);
    cursor: default;
}
.primaryLoadingStyles{
    background: rgba(0, 0, 0, 0.10);
    cursor: default;
}