.modalWrapper{
  width: 504px;
  padding: 40px 40px 37px;
  display: flex;
  gap: 38px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btnBlock{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:22px;

  .mainBtn{
    width: 424px;
  }
}