.resetContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  .content{
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: center;
      .continueBtn{
        margin: 30px 0 22px;
      }
  }
  .resetForm{
    width: 424px;
    display: flex;
    flex-direction: column;
    gap:24px
  }

}
.textBtn{
  width: 100%;
  margin: 38px  auto;
}