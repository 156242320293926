.container{
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 22px;
  .title{
    color: #000;
    text-align: center;
    font-size: 42px;
    font-weight: 500;
    line-height: 53px;
  }
  .subTitle{
    color: #878787;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}